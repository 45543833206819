<template>
  <v-snackbar
    class="card secondary--text"
    v-model="model"
    color="primary"
    width="200%"
    min-height="140"
    timeout="3000"
  >
    <section class="d-flex justify-space-between align-center">
      <v-btn
        height="100"
        class="mx-2 lighten-1"
        width="100"
        color="primary"
        fab
        depressed
      >
        <v-icon size="50">mdi-cart</v-icon>
      </v-btn>
      <p class="card-text">
        <span class="card-title">{{ item }}</span> {{translate('added_to_bag','has been added to bag')}}
      </p>
    </section>
  </v-snackbar>
</template>
<script>
import translationMixin from '@/mixins/translations'
export default {
  name: "BaseNotification",
  mixins: [translationMixin],
  props: {
    item: {
      required: true,
      type: String,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  left: 0;
  right: 0;
  bottom: 250px;
  margin: auto;

  &-text {
    color: #fff !important;
    font-size: 28px;
    line-height: 121.5%;
    letter-spacing: -0.3px;
    span {
      font-weight: bold;
    }
  }
}
</style>