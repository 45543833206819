<template>
    <v-card>
        <v-row no-gutters v-if="true">
            <v-col cols="3"><MenuSidebar :items="menus.data" v-model="selectedCategory" @menu-restart="isRestart = true" /></v-col>
            <v-col class="bg-grey">
                <section class="mx-6 mt-6">
                <div class=" ">
                    <v-img content-class="py-1" src="@/assets/img/dummy/slide/img.png"></v-img>
                </div>
                <section class="">
                    <v-window v-model="selectedCategory">
                        <v-window-item eager v-for="(category, index) in menus.data" :key="index">
                            <h1 class="category-title my-5">{{category.name}}</h1>
                            <section class="category-item d-flex flex-wrap ">
                                <MenuItem v-for="(item, key) in category.attributes.items" :item="item" :key="key" @selected-item="onSelectedItem" />
                            </section>
                        </v-window-item>
                    </v-window>
                </section>
                </section>
            </v-col>
        </v-row>
        <router-view :item="selectedItem" />
        <BaseNotification v-if="isSuccess" v-model="isSuccess" :item="itemName" />
        <AppFooter :total-price="totalPrice" :total-item="totalItem" />
        <base-message 
            v-if="isRestart" 
            v-model="isRestart" 
            :title="translate('restart_order','Restart Order')"
            @confirm="$router.push({name: 'home'})">
            {{translate('confirm_reset_text','Are you sure you want to reset? Your orders will be removed and you will be taken to the homepage')}}
        </base-message>
    </v-card>
</template>
<script>
import translationMixin from '@/mixins/translations'
import MenuSidebar from '@/components/menu/MenuSidebar.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import AppFooter from '@/components/main/AppFooter.vue';
import BaseNotification from '@/components/base/BaseNotification';
import BaseMessage from '@/components/base/BaseMessage';
import { mapGetters, mapMutations } from 'vuex';
export default {
    name:'MenuPage',
    mixins: [translationMixin],
    data: ()=>({
        isSuccess: false,
        totalItem: 10,
        isRestart: false,
        totalPrice: '32.99',
        selectedCategory: 0,
        selectedItem: {},
        itemName: '',
    }),
    components:{
        MenuSidebar,
        MenuItem,
        AppFooter,
        BaseNotification,
        BaseMessage
    },
    computed:{
        ...mapGetters({
            menus: 'cart/getMenu',
            items: 'cart/items'
        })        
    },
    watch: {
        '$route': {
            immediate: true,
            deep: true,
            handler({query}) {
                if(query.item) {
                    setTimeout(() => {
                        this.itemName = query.item
                        this.isSuccess = true
                        this.$router.replace('/menu')
                    },200)
                }
            }
        }        
    },
    mounted() {
        console.log(this.menus)
    },
    methods:{
        ...mapMutations({
            clear: 'cart/clear'
        }),
        reset() {
            this.$router.push({name: 'home'})
            this.clear()                  
        },   
        onSelectedItem(item){
            this.customize(item)
            /**
             * if(item['must-be-customized']) {
             *    this.customize(item)
             * } else {                
             *    this.itemModal(item)
             * } 
             */          
        },
        customize(item) {
            this.$router.push({
                name: 'menu.customize', 
                params:{
                    catId: this.menus.data[this.selectedCategory].id,
                    itemId: `${this.name(item.name).replace(/\s+/g, '-').toLowerCase()}-${item.id}`
                }
            })
        },
        itemModal(item) {
            this.$router.push({
                name: 'menu.add', 
                params:{
                    catId: this.menus.data[this.selectedCategory].id,
                    itemId: `${this.name(item.name).replace(/\s+/g, '-').toLowerCase()}-${item.id}`
                }
            })
        }
    }

}
</script>
<style lang="scss" scoped>
    .category-title{
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 72px;
        letter-spacing: -0.495px;
    }
</style>