<template>
  <section class="sidebar">
    <section class="d-flex justify-center">
      <div class="content text-center">
        <v-img :src="logoURI" class="mb-10 mx-auto" max-width="155"/>
        <div class="my-14 btn">
        <v-btn @click="$emit('menu-restart')" class="btn-restart black--text" elevation="0" outlined block>
          <v-img src="@/assets/img/buttons/restart.svg" max-width="52" />
        </v-btn>
        <span>{{translate('restart', 'Restart')}}</span>
        </div>
        <v-responsive></v-responsive>
        <section class="menu-container">
        <v-item-group v-model="model" >
          <div class="mb-12 " v-for="(item, index) in items" :key="index">
          <v-item v-slot="{ toggle}" active-class="d-flex justify-center active" class="d-flex justify-center">
            <v-card class="card" flat @click="toggle">
              <v-img :src="item.attributes.category['image-uri']" eager contain min-height="150" max-height="150" min-width="150" max-width="150" ></v-img>
            </v-card>
          </v-item>
          <div class="menu-title mt-4" :class="model == index ? 'active-text': null">
            {{item.attributes.category.name | name}}
          </div>  
          </div>
        </v-item-group>
        </section>
      </div>
    </section>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import translationMixin from '@/mixins/translations'
export default {
  name: "MenuSidebar",
  mixins: [translationMixin],
  props:{
    items:{
      type:Array
    },
    value:{
      type: Number,
      default: 0
    }
  },

  computed:{
    ...mapGetters({      
      concept: 'concept/getConcept'
    }),
    logoURI() {
      return this.concept['logo-uri']
    },
    model:{
      get(){return this.value},
      set(v){this.$emit('input', v)}
    }
  } 
};
</script>
<style lang="scss" scoped>
.sidebar {
  height: 100% !important;
  overflow-y: auto;
  font-size: 24px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
    .menu-container{
      overflow-y: auto;
      max-height: 1900px;
    }
  .content {
    margin-top: 51px;
    .btn{
        &-restart{  
        border: 3px solid #000000;
        background: transparent !important;
        box-sizing: border-box;
        border-radius: 20px;
        height: 60px;
      }
        span{
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          color: #1D1D1D;
        }
    }
    .active{
      border-radius: 20px;
      font-style: normal;
      border: 5px solid;
      border-color: var(--v-primary-base);
      width: 150px;
      margin: 0 auto;
    }
    .menu-title{
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: rgba(0, 0, 0, 0.38);
    }
    .active-text{
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    position: relative;
    color:#1D1D1D;
      &:after{
        content: "";
        background-color: var(--v-primary-base);
        height: 5px;
        width: 50px;
        position: absolute;
        bottom: -8px;
        margin-top: 1100px;
        left: 0;
        right: 0;
        margin: 0 auto;

     }
  
    }
  }
}
</style>