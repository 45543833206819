<template>
  <v-card id="card" class="card mb-10" elevation="0" @click="$emit('selected-item', item)">
    <v-img :src="item['image-uri']"></v-img>
    <v-card-actions class="flex-column justify-start align-start">
      <div class="card-title">{{ item.name | name }}</div>
      <div class="card-price mt-3">{{ item.price }} SR</div>
    </v-card-actions>
  </v-card>
</template>
<script>
import translationMixin from '@/mixins/translations'
export default {
  name: "MenuItem",
  mixins: [translationMixin],
  props: {
    item: {
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.card {
  margin-right: 27px;
  width: 227px !important;
  height: 331px !important;
  background: #ffffff !important;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.14) !important;
  border-radius: 20px !important;
  &-price {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: var(--v-primary-base);
  }
  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    color: #000000;
  }
}
</style>